export function authenticationFetch() {
  const { app } = useRuntimeConfig();
  const { backendUrl, backendVersion } = app;
  const uxStore = useUXStore();

  const { $i18n } = useNuxtApp();
  const t = $i18n.t;

  const fetchSession = async (url: string) => {
    try {
      const response: Response = await fetch(url, {
        credentials: "include",
      });
      const result: { IsAuthenticated: boolean } = await response.json();
      return result.IsAuthenticated;
    } catch (error) {
      console.error("Error fetching session", error);
    }
  };

  const attemptLogin = async (emailAddress: string, password: string) => {
    try {
      const body = new FormData();
      body.append("Email", emailAddress);
      body.append("Password", password);

      const response = await fetch(
        `${backendUrl}/api/${backendVersion}/authentication/login/`,
        {
          method: "POST",
          body: body,
          credentials: "include",
        },
      );

      if (response.ok) {
        const responseData = await response.json();
        return responseData;
      }
    } catch (error) {
      console.error("Error fetching CSRF Token", error);
    }
  };

  const checkActivation = async (
    backendUrl: string,
    backendVersion: string,
    activationToken: string,
  ) => {
    try {
      const csrfToken = await fetchCSRFToken();
      const headers: Record<string, string> = {
        "X-CSRFToken": csrfToken,
      };

      const response = await fetch(
        `${backendUrl}/api/${backendVersion}/authentication/activate/?token=${activationToken}`,
        {
          method: "GET",
          headers: headers,
          credentials: "include",
        },
      );

      if (response.ok) {
        const responseData = await response.json();
        return responseData;
      } else {
        uxStore.setAlert(true, {
          type: "error",
          title: t("commons.alerts.errorProfile.titleActivation"),
          subtitle: t("commons.alerts.errorProfile.subActivation"),
          ctaTextRight: {
            label: t("commons.alerts.ctaHome.label"),
            ariaLabel: t("commons.alerts.ctaHome.ariaLabel"),
          },
          doubleCTA: false,
        });
        throw new Error("ERROR FETCHING FROM TOKEN");
      }
    } catch (error) {
      console.error("Error while validating activation token.", error);
    }
  };

  const fetchCSRFToken = async () => {
    try {
      const response = await fetch(
        `${backendUrl}/api/${backendVersion}/authentication/csrftoken/`,
        {
          headers: { "Content-Type": "application/json" },
          credentials: "include",
        },
      );

      if (response.ok) {
        const csrftokenResponseData = await response.json();
        return csrftokenResponseData["XCsrfToken"] || "";
      } else {
        uxStore.setAlert(true, {
          type: "error",
          title: t("commons.alerts.errorProfile.titleCSRF"),
          subtitle: t("commons.alerts.errorProfile.subServer"),
          ctaTextRight: {
            label: t("commons.alerts.ctaHome.label"),
            ariaLabel: t("commons.alerts.ctaHome.ariaLabel"),
          },
          doubleCTA: false,
        });
        throw new Error("ERROR FETCHING CSRF TOKEN");
      }
    } catch (error) {
      console.error("Error fetching CSRF Token", error);
    }
  };

  const attemptLogout = async () => {
    try {
      const csrfToken = await fetchCSRFToken();
      const headers: Record<string, string> = {
        "X-CSRFToken": csrfToken,
      };

      const response = await fetch(
        `${backendUrl}/api/${backendVersion}/authentication/logout/`,
        {
          method: "POST",
          headers: headers,
          credentials: "include",
        },
      );

      if (response.ok) {
        return response.ok;
      }
    } catch (error) {
      console.error("Error fetching CSRF Token", error);
    }
  };

  const attemptRegister = async (password: string, activationToken: string) => {
    try {
      const body = new FormData();
      body.append("Password", password);

      const response = await fetch(
        `${backendUrl}/api/${backendVersion}/authentication/activate/?token=${activationToken}`,
        {
          method: "POST",
          body: body,
          credentials: "include",
        },
      );

      if (response.ok) {
        const responseData = await response.json();
        return responseData;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error fetching CSRF Token", error);
    }
  };

  const attemptResetPassword = async (
    password: string,
    activationToken: string,
  ) => {
    try {
      const body = { Password: password };

      const response = await fetch(
        `${backendUrl}/api/${backendVersion}/authentication/reset-password/?token=${activationToken}`,
        {
          method: "PUT",
          body: JSON.stringify(body),
          credentials: "include",
        },
      );

      if (response.ok) {
        const responseData = await response.json();
        return responseData;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error while reseting password token.", error);
    }
  };

  const checkResetActivation = async (
    backendUrl: string,
    backendVersion: string,
    activationToken: string,
  ) => {
    try {
      const response = await fetch(
        `${backendUrl}/api/${backendVersion}/authentication/reset-password/?token=${activationToken}`,
        {
          method: "GET",
          credentials: "include",
        },
      );

      if (response.ok) {
        const responseData = await response.json();
        return responseData;
      } else {
        uxStore.setAlert(true, {
          type: "error",
          title: t("commons.alerts.errorProfile.titleResetPassword"),
          subtitle: t("commons.alerts.errorProfile.subResetPassword"),
          ctaTextRight: {
            label: t("commons.alerts.ctaHome.label"),
            ariaLabel: t("commons.alerts.ctaHome.ariaLabel"),
          },
          doubleCTA: false,
        });
        throw new Error("ERROR FETCHING FROM TOKEN");
      }
    } catch (error) {
      console.error("Error while validating reset password token.", error);
    }
  };

  const attemptResetPasswordEmail = async (email: string) => {
    try {
      const body = new FormData();
      body.append("Email", email);

      const response = await fetch(
        `${backendUrl}/api/${backendVersion}/authentication/reset-password-email/`,
        {
          method: "POST",
          body: body,
          credentials: "include",
        },
      );

      if (response.ok) {
        const responseData = await response.json();
        return responseData;
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error fetching CSRF Token", error);
    }
  };

  return {
    checkActivation,
    fetchCSRFToken,
    attemptLogout,
    attemptRegister,
    attemptLogin,
    attemptResetPassword,
    checkResetActivation,
    attemptResetPasswordEmail,
    fetchSession,
  };
}
